import { useSearchParams } from 'react-router-dom';
import './daigou.css'

function Privacy() {
    const [searchParams] = useSearchParams();
    const theme = searchParams.get('theme');
    const language = searchParams.get('language') || '英文';
    const device = searchParams.get('device');//设备

    const newTheme = theme === 'dark' ? 'dark' : 'light';
    document.body.className = newTheme;
    if (language === '中文') {
        return (
            <div>
                <h2>代购管家App隐私政策</h2>
                <p>
                    欢迎使用代购管家App（以下简称“本应用”）,代购管家App由广州野离子网络科技有限公司研发和维护。我们非常重视用户的隐私权，承诺保护用户的个人信息。请你仔细阅读本隐私政策，以便你了解我们如何收集、使用、存储和保护你的信息。
                </p>
                <h3>1、信息的收集</h3>
                <p>
                    我们可能会收集以下类型的信息：
                </p>
                <ul>
                    <li>个人信息：包括你的手机号、电子邮箱地等，当你注册账户或使用我们的服务时收集。</li>
                    <li>使用信息：包括你如何使用本应用的信息，例如访问的页面、使用的功能和操作时间。</li>
                    <li>设备信息：包括你的设备类型、操作系统、IP地址等。</li>
                </ul>

                <h3>2、信息使用</h3>
                <p>我们可能会将手机到的信息用于以下目的：</p>
                <ul>
                    <li>提供和维护我们的服务。</li>
                    <li>改进和优化本应用的用户体验。</li>
                    <li>为你提供客户支持</li>
                </ul>

                <h3>3、信息分享</h3>
                <p>我们承诺不会将你的个人信息出售或出租给任何第三方。我们可能会在以下情况下共享你的信息：</p>
                <ul>
                    <li>经过你的同意</li>
                    <li>法律法规要求或为维护我们的合法权益</li>
                </ul>

                <h3>4、信息的存储和安全</h3>
                <p>我们将采取合理的措施保护你的个人信息免受未经授权的访问、使用或泄露。你的信息将被存储在安全的服务器上，只有经过授权的人员才能访问。</p>

                <h3>5、用户的权利</h3>
                <p>你有权访问、修改或删除我们持有的关于你的个人信息。可以在软件中账号信息界面注销账号。</p>

                <h3>6、政策的变更</h3>
                <p>我们可能会不时更新本隐私政策。我们将通过本应用发布更新的隐私政策，并注明生效日期。请定期查看，以确保你了解我们的隐私保护措施。</p>

                <h3>6、联系我们</h3>
                <p>如果你对本隐私政策有任何疑问或建议，请通过电子邮件联系我们，地址：pengappdev@qq.com</p>


                <p>感谢你使用代购管家App，我们将竭诚为你提供服务！</p>
                <p style={{ marginTop: '20px', fontSize: 14 }}>更新日期：2024年12月23日<br />生效日期：2024年9月28日</p>
            </div>
        )
    } else if (language === '繁体') {
        return (
            <div>
                <h2>代購管家App隱私政策</h2>
                <p>
                    歡迎使用代購管家App（以下簡稱“本應用”），代購管家App由廣州野離子網絡科技有限公司研發和維護。 我們非常重視用戶的隱私權，承諾保護用戶的個人資訊。 請你仔細閱讀本隱私政策，以便你瞭解我們如何收集、使用、存儲和保護你的資訊。
                </p>
                <h3>1、信息的收集</h3>
                <p>
                    我們可能會收集以下類型的信息：
                </p>
                <ul>
                    <li>個人信息：包括你的手機號、電子郵箱等，當你註冊賬戶或使用我們的服務時收集。</li>
                    <li>使用信息：包括你如何使用本應用的信息，例如訪問的頁面、使用的功能和操作時間。</li>
                    <li>設備信息：包括你的設備類型、操作系統、IP地址等。</li>
                </ul>

                <h3>2、信息使用</h3>
                <p>我們可能會將收集到的信息用於以下目的：</p>
                <ul>
                    <li>提供和維護我們的服務。</li>
                    <li>改善和優化本應用的用戶體驗。</li>
                    <li>為你提供客戶支持。</li>
                </ul>

                <h3>3、信息分享</h3>
                <p>我們承諾不會將你的個人信息出售或出租給任何第三方。我們可能會在以下情況下共享你的信息：</p>
                <ul>
                    <li>經過你的同意。</li>
                    <li>法律法規要求或為維護我們的合法權益。</li>
                </ul>

                <h3>4、信息的存儲和安全</h3>
                <p>我們將採取合理的措施保護你的個人信息免受未經授權的訪問、使用或洩露。你的信息將被存儲在安全的伺服器上，只有經過授權的人員才能訪問。</p>

                <h3>5、用戶的權利</h3>
                <p>你有權訪問、修改或刪除我們持有的關於你的個人信息。可以在軟件中賬號信息界面註銷賬號。</p>

                <h3>6、政策的變更</h3>
                <p>我們可能會不時更新本隱私政策。我們將通過本應用發布更新的隱私政策，並注明生效日期。請定期查看，以確保你了解我們的隱私保護措施。</p>

                <h3>7、聯繫我們</h3>
                <p>如果你對本隱私政策有任何疑問或建議，請通過電子郵件聯繫我們，地址：pengappdev@qq.com</p>

                <p>感謝你使用代購管家App，我們將竭誠為你提供服務！</p>
                <p style={{ marginTop: '20px', fontSize: 14 }}>更新日期：2024年12月23日<br />生效日期：2024年9月28日</p>
            </div>
        )
    } else {
        return (
            <div>
                <h2>ShopMaster App Privacy Policy</h2>
                <p>
                    Welcome to use the ShopMaster App (hereinafter referred to as "the App"), which is developed and maintained by Guangzhou Yeion Network Technology Co., Ltd. We attach great importance to users' privacy rights and promise to protect their personal information. Please read this privacy policy carefully so that you understand how we collect, use, store, and protect your information.
                </p>
                <h3>1. Information Collection</h3>
                <p>
                    We may collect the following types of information:
                </p>
                <ul>
                    <li>Personal Information: This includes your phone number, email address, etc., collected when you register an account or use our services.</li>
                    <li>Usage Information: This includes information about how you use this application, such as the pages you visit, features you use, and the duration of your interactions.</li>
                    <li>Device Information: This includes your device type, operating system, IP address, etc.</li>
                </ul>

                <h3>2. Information Use</h3>
                <p>We may use the information collected for the following purposes:</p>
                <ul>
                    <li>To provide and maintain our services.</li>
                    <li>To improve and optimize the user experience of this application.</li>
                    <li>To provide you with customer support.</li>
                </ul>

                <h3>3. Information Sharing</h3>
                <p>We promise not to sell or rent your personal information to any third party. We may share your information in the following situations:</p>
                <ul>
                    <li>With your consent.</li>
                    <li>As required by law or to protect our legitimate rights.</li>
                </ul>

                <h3>4. Information Storage and Security</h3>
                <p>We will take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. Your information will be stored on secure servers, accessible only to authorized personnel.</p>

                <h3>5. User Rights</h3>
                <p>You have the right to access, modify, or delete your personal information that we hold. You can deactivate your account in the account information section of the application.</p>

                <h3>6. Policy Changes</h3>
                <p>We may update this privacy policy from time to time. We will publish the updated privacy policy through this application, noting the effective date. Please check regularly to ensure you understand our privacy protection measures.</p>

                <h3>7. Contact Us</h3>
                <p>If you have any questions or suggestions regarding this privacy policy, please contact us via email at: pengappdev@qq.com</p>

                <p>Thank you for using the ShopMaster App. We are dedicated to providing you with excellent service!</p>
                <p style={{ marginTop: '20px', fontSize: 14 }}>Last updated: Dec 23, 2024<br />Effective date: Sep 28, 2024</p>
            </div>
        )
    }
}

export default Privacy;